import { ReactElement } from "react";

import DefaultLayout from "components/Layouts/DefaultLayout";
import Logo from "svg/logo.svg";
export default function Page404() {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <Logo className="h-20" />
      <h1 className="text-2xl">Error 404</h1>
    </div>
  );
}

Page404.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};
